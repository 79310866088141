import React from "react";
import "./projects.css";
import { Link } from "react-router-dom";
type Props = {};

const WaterWell = (props: Props) => {
  return (
    <div className="projects container">
      <div className="item__container">
        <div className="head__container">
          <p>Su Kuyuları</p>
        </div>

        <div className="content__container">
          <p>
            Vakfımız hayırseverlerin destekleriyle bir yudum su için
            kilometrelerce yol kat etmek zorunda kalan insanlara temiz içme suyu
            sağlamak, kirli suyun neden olduğu bulaşıcı ve salgın hastalıkları
            önlemek, su sıkıntısı nedeniyle kullanılamayan tarım alanlarına su
            temin etmek ve bölge insanının kalkınmasına destek olmak amaçlarıyla
            halihazırda Güney Asya ülkelerinden Afganistan ve Afrika
            ülkelerinden Afganistan, Burkina Faso, Gana, Ka­merun, Mali, Nijer,
            Nijerya, Somali, Sierra Leone ve Tanzanya’ dahil birçok ülkede su
            kuyusu açma projeleri gerçekleştirmektedir.
          </p>
          <p>
            Açılan kuyuların bakımları düzenli olarak yapılmakta, zaman
            içerisinde kuyularda meydana gelebilecek arıza ve tamir masrafları
            vakfımız tarafından karşılanmaktadır. Kuyu fiyatları ülkelere,
            açılacak bölgenin ve zeminin durumuna ayrıca kuyunun niteliğine göre
            değişmektedir.
          </p>
          <p>
            Detaylı bilgi için bizimle <Link to="/contact">iletişime</Link> geçiniz.
          </p>

          <p>
            <p>
              Online Bağış yapmak için{" "}
              <Link to="https://www.hudayivakfi.org.tr/online_bagis/su-kuyusu">
                tıklayınız.
              </Link>
            </p>
          </p>
        </div>
      </div>
    </div>
  );
};

export default WaterWell;
