import API from '../API/API';
import { BrandModel } from '../Models/Brands/Brand';


export const getBrands =async() : Promise<BrandModel[]> => {
    try{
        const response = await API.get<BrandModel[]>('/brands');
        return response.data;
    }
    catch(error){
        throw new Error ('Failed to fetch Brands');
    }
}