import API from '../API/API';
import { DonationModel } from '../Models/Donations/Donations';


export const getDonations =async() : Promise<DonationModel[]> => {
    try{
        const response = await API.get<DonationModel[]>('/donations');
        return response.data;
    }
    catch(error){
        throw new Error ('Failed to fetch Donations');
    }
}