import React from 'react'
import "../../pages/LegalPages/legalPage.css"
type Props = {}

const CookiePolicy = (props: Props) => {
  return (
    <div className="legal__container">
    <h1>Çerez Politikası</h1>
    <p><small><i>Güncellenme tarihi: 02 Aralık 2023</i></small></p>
    <p>
      Bu Çerez Politikası, Çerezlerin ne olduğunu ve bunları nasıl kullandığımızı açıklar. Hangi tür çerezleri kullandığımızı veya Çerezleri kullanarak topladığımız bilgileri ve bu bilgilerin nasıl kullanıldığını anlamanız için bu politikayı okumalısınız. Bu Çerez Politikası, <a href="https://www.termsfeed.com/cookies-policy-generator/" target="_blank" rel="noopener noreferrer">Çerez Politikası Üretici</a>'nin yardımıyla oluşturulmuştur.
    </p>
    <p>
      Çerezler genellikle bir kullanıcıyı kişisel olarak tanımlayan herhangi bir bilgi içermez, ancak sizin hakkınızda sakladığımız kişisel bilgiler, Çerezlerde depolanan ve Çerezlerden elde edilen bilgilerle bağlantılı olabilir. Kişisel verilerinizi nasıl kullandığımız, sakladığımız ve güvende tuttuğumuzla ilgili daha fazla bilgi için Gizlilik Politikamıza bakın.
    </p>
    <p>
      Kullandığımız Çerezlerde posta adresleri, hesap şifreleri gibi hassas kişisel bilgileri saklamıyoruz.
    </p>
    <h2>Yorumlama ve Tanımlar</h2>
    <h3>Yorumlama</h3>
    <p>
      Baş harfi büyük olan kelimelerin anlamları aşağıdaki koşullarda tanımlanmıştır. Aşağıdaki tanımlar, tekil veya çoğul olarak görünüp görünmemelerine bakılmaksızın aynı anlama gelir.
    </p>
    <h3>Tanımlar</h3>
    <p>Bu Çerez Politikası amacıyla:</p>
    <ul>
      <li><strong>Şirket</strong> (Bu Çerez Politikasında "Şirket", "Biz", "Bizi" veya "Bizim" olarak da anılabilir) Muradiye Kültür Derneği, Kadıköy Mahallesi Taşer Sokak No:10 İzmit / KOCAELİ'yi ifade eder.</li>
      <li><strong>Çerezler</strong>, bir web sitesi tarafından cihazınıza yerleştirilen, tarama geçmişinizle ilgili bilgileri içeren küçük dosyalar anlamına gelir.</li>
      <li><strong>Web Sitesi</strong>, <a href="https://muradiyekulturdernegi.org/" rel="external nofollow noopener" target="_blank">https://muradiyekulturdernegi.org/</a> adresinden erişilebilen Muradiye Kültür Derneği'ni ifade eder.</li>
      <li><strong>Siz</strong>, Web Sitesini kullanan veya Web Sitesine erişen bireyi veya bu tür bir bireyin adına Web Sitesini kullanan herhangi bir tüzel kişiliği ifade eder.</li>
    </ul>
    <h2>Çerezlerin Kullanımı</h2>
    <h3>Kullandığımız Çerez Türleri</h3>
    <p>
      Çerezler "Kalıcı" veya "Oturum" Çerezleri olabilir. Kalıcı Çerezler, çevrimdışıyken kişisel bilgisayarınızda veya mobil cihazınızda kalırken, Oturum Çerezleri, web tarayıcınızı kapattığınız anda silinir.
    </p>
    <p>
      Aşağıda belirtilen amaçlar doğrultusunda hem oturum hem de kalıcı çerezler kullanıyoruz:
    </p>
    <ul>
      <li>
        <p><strong>Gerekli / Temel Çerezler</strong></p>
        <p>Tür: Oturum Çerezleri</p>
        <p>Yöneten: Muradiye Kültür Derneği</p>
        <p>Amaç: Bu Çerezler, Web Sitesi aracılığıyla size sunulan hizmetleri sağlamak ve bazı özelliklerini kullanmanızı sağlamak için gereklidir. Kullanıcıları doğrulamaya ve kullanıcı hesaplarının kötüye kullanılmasını önlemeye yardımcı olurlar. Bu Çerezler olmadan talep ettiğiniz hizmetler sağlanamaz ve bu Çerezleri yalnızca bu hizmetleri size sağlamak için kullanırız.</p>
      </li>
      <li>
        <p><strong>İşlevsel Çerezler</strong></p>
        <p>Tür: Kalıcı Çerezler</p>
        <p>Yöneten: Muradiye Kültür Derneği</p>
        <p>Amaç: Bu Çerezler, Web Sitesini kullandığınızda yaptığınız seçimleri hatırlamamıza olanak tanır, örneğin giriş bilgilerinizi veya dil tercihlerinizi hatırlamak gibi. Bu Çerezlerin amacı, size daha kişisel bir deneyim sunmak ve Web Sitesini her kullandığınızda tercihlerinizi yeniden girmenize gerek kalmamasını sağlamaktır.</p>
      </li>
    </ul>
    <h3>Çerezlerle İlgili Seçenekleriniz</h3>
    <p>
      Web Sitesinde Çerez kullanımını engellemeyi tercih ederseniz, önce tarayıcınızda Çerez kullanımını devre dışı bırakmalı, ardından bu web sitesiyle ilişkili olarak tarayıcınızda kaydedilen Çerezleri silmelisiniz. Çerezlerin kullanımını herhangi bir zamanda engellemek için bu seçeneği kullanabilirsiniz.
    </p>
    <p>
      Çerezlerimizi kabul etmezseniz, Web Sitesini kullanırken bazı zorluklarla karşılaşabilirsiniz ve bazı özellikler düzgün çalışmayabilir.
    </p>
    <p>
      Çerezleri silmek veya tarayıcınızın Çerezleri silmesini veya reddetmesini istemek isterseniz, lütfen tarayıcınızın yardım sayfalarını ziyaret edin.
    </p>
    <ul>
      <li>
        <p>Chrome web tarayıcısı için, lütfen Google'ın şu sayfasını ziyaret edin: <a href="https://support.google.com/accounts/answer/32050" rel="external nofollow noopener" target="_blank">https://support.google.com/accounts/answer/32050</a></p>
      </li>
      <li>
        <p>Internet Explorer web tarayıcısı için, lütfen Microsoft'un şu sayfasını ziyaret edin: <a href="http://support.microsoft.com/kb/278835" rel="external nofollow noopener" target="_blank">http://support.microsoft.com/kb/278835</a></p>
      </li>
      <li>
        <p>Firefox web tarayıcısı için, lütfen Mozilla'nın şu sayfasını ziyaret edin: <a href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored" rel="external nofollow noopener" target="_blank">https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored</a></p>
      </li>
      <li>
        <p>Safari web tarayıcısı için, lütfen Apple'ın şu sayfasını ziyaret edin: <a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac" rel="external nofollow noopener" target="_blank">https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac</a></p>
      </li>
    </ul>
    <p>Diğer herhangi bir web tarayıcısı için, lütfen tarayıcınızın resmi web sayfalarını ziyaret edin.</p>
    <h3>Çerezler Hakkında Daha Fazla Bilgi</h3>
    <p>
      Çerezler hakkında daha fazla bilgi edinebilirsiniz: <a href="https://www.termsfeed.com/blog/cookies/" target="_blank" rel="noopener noreferrer">TermsFeed tarafından Çerezler Hakkında Her Şey</a>.
    </p>
    <h3>Bize Ulaşın</h3>
    <p>
      Bu Çerez Politikası hakkında herhangi bir sorunuz varsa, bizimle iletişime geçebilirsiniz:
    </p>
    <ul>
      <li>E-posta ile: <a href="mailto:iletisim@muradiyekulturdernegi.org">iletisim@muradiyekulturdernegi.org</a></li>
    </ul>
  </div>
  )
}

export default CookiePolicy
