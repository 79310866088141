import React from 'react'
import "./donate.css"
type Props = {}

const Donate = (props: Props) => {
  return (
    <div className='donate'>
        <div className="donate__container container">
            <div className="header__container">
                <p>Bağış Hesap Numaralarımız</p>
            </div>
            <div className="content__container">
                <div className="logo__container">
                    <img alt="muradiye-logo" src='https://muradiyekulturdernegi.org/assets/muradiye_logo_png.png'/>
                </div>
                <div className="text__container">
                    <p>HESAP ADI : <span>Muradiye Eğitim Kültür ve Yardımlaşma Derneği
                    </span></p>
                    <p>IBAN NO : <span>TR86 0020 5000 0971 2908 7000 01</span> </p>
                    <p>HESAP NO :  <span>97129087-1 </span></p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Donate