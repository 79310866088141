import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { MdLanguage } from "react-icons/md";
import { NavDropdown } from "react-bootstrap";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import "./navbar.css";
import { getSettings } from "../../services/SettingsService";

const NewNavbar: React.FC = () => {
  const [isNavbarOpen, setIsNavbarOpen] = useState<boolean>(false); 
  const [headerClass, setHeaderClass] = useState<string>("header");
  const [settings, setSettings] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);

  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen); 
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 800) {
        setHeaderClass("header addBg");
      } else {
        setHeaderClass("header");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsNavbarOpen(false); 
      }
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const settingsData = await getSettings();
        setSettings(settingsData[0]);
      } catch (error) {
        setError((error as Error).message);
      }
    };
    fetchData();
  }, []);
  const Menu = () => (
    <>
      <ul className="menu">
        <li onClick={toggleNavbar} className="listItem">
          <Link to="/" className="link">
            Anasayfa
          </Link>
        </li>

       {/* <NavDropdown
          title="Projeler"
          id="basic-nav-dropdown"
          className="listItem dropdownMenu languageBtnContainer"
        >
          <NavDropdown.Item onClick={toggleNavbar} as={Link} to="/kurban">
            Kurban Bağışı
          </NavDropdown.Item>
          <NavDropdown.Item onClick={toggleNavbar} as={Link} to="/su-kuyulari">
            Su Kuyuları
          </NavDropdown.Item>
          <NavDropdown.Item onClick={toggleNavbar} as={Link} to="/egitim">
            Eğitim
          </NavDropdown.Item>
        </NavDropdown>*/}

        <li onClick={toggleNavbar} className="listItem">
          <Link to="/events" className="link">
            Etkinlikler
          </Link>
        </li>
        <li onClick={toggleNavbar} className="listItem">
          <Link to="/gallery" className="link">
            Galeri
          </Link>
        </li>
        <li onClick={toggleNavbar} className="listItem">
          <Link to="/donate" className="link">
            Bağış Yap
          </Link>
        </li>
        <NavDropdown
          title="Hakkımızda"
          id="basic-nav-dropdown"
          className="listItem dropdownMenu languageBtnContainer"
        >
          <NavDropdown.Item onClick={toggleNavbar} as={Link} to="/about-us">
            Biz Kimiz ?
          </NavDropdown.Item>
          <NavDropdown.Item onClick={toggleNavbar} as={Link} to="/institutions">
            Temsilcilikler
          </NavDropdown.Item>
          <NavDropdown.Item onClick={toggleNavbar} as={Link} to="/contact">
            İletişim
          </NavDropdown.Item>
        </NavDropdown>
      </ul>
    </>
  );

  return (
    <>
      <div className={headerClass}>
        <div className="logoDiv">
          <Link to="/" className="link company-title logoDiv">
            <img
              src={
                settings && settings.logo
                  ? `https://muradiyekulturdernegi.org/panel/uploads/settings_v/${settings.logo}`
                  : "https://muradiyekulturdernegi.org/assets/muradiye_logo_png.png"
              }
              alt="muradiye-logo"
              className="logo"
            />
            {settings && settings.company_name
              ? settings.company_name
              : "Loading.."}
          </Link>
        </div>
        <div className={`navbar ${isNavbarOpen ? "showNavbar" : ""}`}>
          <Menu />
        </div>

        <div className="signUp flex">
          

          {isNavbarOpen ? (
            <RiCloseLine
              className="icon toggleNavbarIcon"
              onClick={toggleNavbar}
            />
          ) : (
            <RiMenu3Line
              className="icon toggleNavbarIcon"
              onClick={toggleNavbar}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default NewNavbar;
