import React, { useEffect } from 'react';
import QRCode from "qrcode"; 

const QRCodeComponent = () => {
    const qrData = "https://maps.app.goo.gl/dEJ8EPV5PNrvCQEc6";

    useEffect(() => {
        const qrcodeCanvas = document.getElementById("qrcode") as HTMLCanvasElement;
        if (qrcodeCanvas) {
            QRCode.toCanvas(qrcodeCanvas, qrData, {
                width: 64,
                margin: 2 
            });
        }
    }, [qrData]);

    return (
        <div>
            <canvas id="qrcode" style={{ width: "64px", height: "64px" }}></canvas>
        </div>
    );
}

export default QRCodeComponent;
