// EventCard.tsx
import React, { useState } from "react";
import "../../pages/Events/events.css";
import { EventModel } from "../../Models/Events/Events";

interface EventCardProps {
  event: EventModel & { category: string; imageUrl?: string };
}
const BASE_URL = "https://muradiyekulturdernegi.org/panel/uploads/portfolios_v/";
const monthsInTurkish = [
  "Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran",
  "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"
];
const EventCard: React.FC<EventCardProps> = ({ event }) => {
  const [isHovered, setIsHovered] = useState(false);
  const imageUrl = event.imageUrl ? `${BASE_URL}${event.imageUrl}` : "";
  const date = new Date(event.date);
  const day = date.getDate();

  const monthIndex = date.getMonth(); 
  const month = monthsInTurkish[monthIndex]; 
  return (
    <div
      className="event__card"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className={`post-module ${isHovered ? "hover" : ""}`}>
        <div className="thumbnail">
          <div className="date">
            <div className="day">{day}</div>
            <div className="month">{month}</div>
          </div>
          <img src={event.imageUrl} alt="Thumbnail" />
        </div>
        <div className="post-content">
          <div className="category">{event.category}</div>
          <h1 className="title">{event.title}</h1>
          <p className={`description ${isHovered ? "show" : ""}`}>
            Yer: {event.location} <br />
            Tarih: {new Date(event.date).toLocaleDateString()}
            <br />
            <br />
            <small>
              <i>Not: {event.description}</i>
            </small>
          </p>
        </div>
      </div>
    </div>
  );
};

export default EventCard;
