import React, { useEffect, useState } from "react";
import {
  About,
  Institutions,
  Projects,
  Services,
  Slider,
  Summary,
} from "../../components";
import "./home.css";
import "../../styles/cards/cards.css";
import { UserModel } from "../../Models/Users/User";
import { getUsers } from "../../services/UserService";

const Home: React.FC = () => {
  const [containerHeight, setContainerHeight] = useState("100vh");

  const handleScroll = () => {
    const newHeight = Math.max(100 - window.scrollY / 1, 10); // 100vh başlangıç, 50vh minimum
    setContainerHeight(`${newHeight}vh`);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="section__home">
      <>
        <div className="container__about">
          <About />
        </div>
        <div className="container__services">
          <Services />
        </div>
        <div className="container__slider">
          <Slider />
        </div>
        <div className="institutions__container">
          <Institutions />
        </div>
      </>
    </div>
  );
};

export default Home;
