import React, { useEffect, useState } from "react";
import "./institutionComponent.css";
import { InstitutionsModel } from "../../Models/Institutions/Institutions";
import { getInstitutions } from "../../services/InstitutionService";
import { Link } from "react-router-dom";
type Props = {};
const BASE_URL = "https://muradiyekulturdernegi.org/panel/uploads/institutions_v/";
const Institutions = (props: Props) => {
  const [inst, setInst] = useState<InstitutionsModel[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const instData = await getInstitutions();
        setInst(instData);
      } catch (error) {
        console.error("Veri alınırken bir hata oluştu:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <>
      <div className="header__container">
        <p>Kurumlarımız</p>
      </div>
      <div className="institutions__component">
        <div className="content__container">
          {inst.map((institution) => (
            <div className="inst_card__container">
              <div className="img__container">
                <Link to={institution.url}>
                <img
                  src={`${BASE_URL}${institution.img_url}`}
                  alt={institution.title}
                />
                </Link>
              </div>
              <div className="text__container">
                <div className="title__container">
                  <Link to={institution.url} title="Instagram Sayfasına Git">
                    {institution.title}
                  </Link>
                </div>

                <div className="address__container">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: institution.description
                        .replace(/<\/?p>/g, "")
                        .replace(/(\d)(?=[^\d])/g, "$1<br/>"),
                    }}
                  ></p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Institutions;
