// src/api/api.ts
import axios from 'axios';

const api = axios.create({
    /*baseURL: 'http://localhost/muradiyee/panel/api', */
    baseURL: 'https://muradiyekulturdernegi.org/panel/api', 
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json',
        
    }
});


api.interceptors.request.use(
    (config) => {
        
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);


api.interceptors.response.use(
    (response) => response,
    (error) => {
       
        return Promise.reject(error);
    }
);

export default api;
