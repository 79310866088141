import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

// `BasicSelectProps` türünü tanımlayın
interface BasicSelectProps {
  selectedLocation: string;
  handleLocationChange: (event: SelectChangeEvent<string>) => void;
  locations: string[];
}

const BasicSelect: React.FC<BasicSelectProps> = ({ selectedLocation, handleLocationChange, locations }) => {
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Etkinlik Yeri</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedLocation}
          label="Etkinlik Yeri"
          onChange={handleLocationChange}
        >
          {locations.map((location) => (
            <MenuItem key={location} value={location}>
              {location}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export default BasicSelect;
