import React from 'react'

type Props = {}

const TermsOfUse = (props: Props) => {
  return (
    <div className='legal__container'>
        <h1>Şartlar ve Koşullar</h1>
      <p><small><i>Güncelleme: 02 Aralık 2023</i></small></p>
      <p>Lütfen Hizmetimizi kullanmadan önce bu şartları dikkatlice okuyun.</p>
      
      <h2>Yorum ve Tanımlar</h2>
      <h3>Yorum</h3>
      <p>
        İlk harfi büyük olan kelimeler, aşağıdaki koşullara göre tanımlanmış anlamlara sahiptir. Bu tanımlar,
        tekil ya da çoğul olarak kullanıldığında aynı anlama gelir.
      </p>
      
      <h3>Tanımlar</h3>
      <p>Bu Şartlar ve Koşullar'ın amaçları doğrultusunda:</p>
      <ul>
        <li>
          <p><strong>Bağlı Kuruluş</strong>: Bir tarafı kontrol eden, onun tarafından kontrol edilen veya ortak
            kontrol altında olan bir varlık anlamına gelir. "Kontrol", yönetim kurulunu seçme hakkına sahip
            hisselerin, özkaynak paylarının veya diğer menkul kıymetlerin %50 veya daha fazlasının mülkiyeti
            anlamına gelir.</p>
        </li>
        <li><p><strong>Ülke</strong>: Türkiye anlamına gelir.</p></li>
        <li>
          <p><strong>Şirket</strong>: (bu sözleşmede "Şirket", "Biz" veya "Bizim" olarak anılacaktır) Muradiye
            Kültür Derneği, Kadıköy Mahallesi Taşer Sokak No:10 İzmit / KOCAELİ anlamına gelir.</p>
        </li>
        <li><p><strong>Cihaz</strong>: Bilgisayar, cep telefonu veya dijital tablet gibi Hizmete erişim sağlayan
          herhangi bir cihaz anlamına gelir.</p></li>
        <li><p><strong>Hizmet</strong>: Web sitesi anlamına gelir.</p></li>
        <li>
          <p><strong>Şartlar ve Koşullar</strong>: Bu Şartlar ve Koşullar, Hizmeti kullanırken sizinle Şirket
            arasında yapılan tüm anlaşmaları ifade eder.</p>
        </li>
        <li>
          <p><strong>Üçüncü Taraf Sosyal Medya Hizmeti</strong>: Hizmet tarafından görüntülenebilen, dahil
            edilebilen veya sunulabilen üçüncü taraf hizmetleri veya içerikleri (veriler, bilgiler, ürünler
            veya hizmetler dahil) anlamına gelir.</p>
        </li>
        <li>
          <p><strong>Web sitesi</strong>: Muradiye Kültür Derneği'nin web sitesi, <a
            href="https://muradiyekulturdernegi.org/" target="_blank" rel="external nofollow noopener">
            https://muradiyekulturdernegi.org/</a> adresinden erişilebilir.</p>
        </li>
        <li>
          <p><strong>Sen</strong>: Hizmete erişen veya Hizmeti kullanan kişi anlamına gelir.</p>
        </li>
      </ul>

      <h2>Onaylama</h2>
      <p>Bu Şartlar ve Koşullar, sizinle Şirket arasında yapılan anlaşmayı temsil eder.</p>
      <p>
        Hizmete erişim ve kullanımınız, bu Şartlar ve Koşullar'a bağlıdır. Hizmeti kullanarak bu
        Şartlar ve Koşullar'a uymayı kabul ediyorsunuz.
      </p>
      <p>
        18 yaşından büyük olduğunuzu beyan ediyorsunuz. Şirket, 18 yaşından küçük kişilerin Hizmeti
        kullanmasına izin vermez.
      </p>
      
      <h2>Diğer Web Sitelerine Bağlantılar</h2>
      <p>
        Hizmetimiz, Şirket tarafından sahip olunmayan veya kontrol edilmeyen üçüncü taraf web sitelerine
        veya hizmetlerine bağlantılar içerebilir. Şirket, bu üçüncü taraf web sitelerinin veya hizmetlerinin
        içeriği, gizlilik politikaları veya uygulamaları konusunda sorumluluk almaz.
      </p>
      
      <h2>Fesih</h2>
      <p>Bu Şartlar ve Koşullar'ı ihlal ederseniz, Hizmete erişiminizi derhal sonlandırabiliriz.</p>
      
      <h2>Sorumluluğun Sınırlandırılması</h2>
      <p>
        Şirket ve tedarikçilerinin size karşı sorumluluğu, Hizmet üzerinden ödenen miktarla sınırlıdır.
        Bazı eyaletlerde bu sorumluluk sınırlandırmaları uygulanmayabilir.
      </p>

      <h2>"Olduğu Gibi" ve "Mevcut Olduğu Şekilde" Feragatnamesi</h2>
      <p>
        Hizmet, tüm kusurları ile olduğu gibi sağlanır. Şirket, Hizmetin beklentilerinizi karşılayacağı
        konusunda herhangi bir garanti vermez.
      </p>

      <h2>Geçerli Hukuk</h2>
      <p>Bu Şartlar ve Hizmeti kullanımınız, Türkiye yasalarına tabidir.</p>

      <h2>Değişiklikler</h2>
      <p>
        Şirket, bu Şartlar ve Koşullar'ı herhangi bir zamanda değiştirme hakkını saklı tutar. Önemli bir
        değişiklik olduğunda, sizi bilgilendirmek için makul bir çaba göstereceğiz.
      </p>

      <h2>Bize Ulaşın</h2>
      <p>
        Bu Şartlar ve Koşullar hakkında sorularınız varsa, bizimle şu adresten iletişime geçebilirsiniz:
      </p>
      <ul>
        <li>Email ile: <a href="mailto:iletisim@muradiyekulturdernegi.org">iletisim@muradiyekulturdernegi.org</a></li>
      </ul>
    </div>
  )
}

export default TermsOfUse