import React, { useEffect, useState } from "react";
import "./institutions.css";
import { InstitutionsModel } from "../../Models/Institutions/Institutions";
import { getInstitutions } from "../../services/InstitutionService";
import { Link } from "react-router-dom";

const BASE_URL = "https://muradiyekulturdernegi.org/panel/uploads/institutions_v/";

const Institutions = () => {
  const [inst, setInst] = useState<InstitutionsModel[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getInstitutions();
        setInst(data);
      } catch (error) {
        setError((error as Error).message);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="institutions container-fluid">
      <div className="header__container">
        <p>Kurumlarımız</p>
      </div>
      <div className="sub__container">
      {inst.map((institution) => (
        <div className="content__container">
          <div className="img__container" key={institution.id}>
            <img
              src={`${BASE_URL}${institution.img_url}`}
              alt={institution.title}
            />
          </div>
          <div className="address__container">
            <div className="sub__header__container">
              <Link title="Instagram sayfasına git" to={institution.url}>
                {institution.title}
              </Link>
            </div>
            <div className="text__container">
              <p
                dangerouslySetInnerHTML={{
                  __html: institution.description
                    .replace(/<\/?p>/g, "") 
                    .replace(/(\d)(?=[^\d])/g, "$1<br/>"),
                }}
              ></p>
            </div>
          </div>
        </div>
      ))}
      </div>
      
    </div>
  );
};

export default Institutions;
