import API from '../API/API';
import { ImageModel } from '../Models/Gallery/Image';

export const getGalleryImages = async (galleryId: number): Promise<ImageModel[]> => {
    try {
        const response = await API.get<ImageModel[]>(`/gallery/images/${galleryId}`);
        return response.data;
    } catch (error) {
        throw new Error('Failed to fetch images');
    }
};
