import {
  FaChevronUp,
  FaPhone,
  FaEnvelope,
  FaMap,
  FaYoutube,
  FaInstagram,
  FaBook,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import "./footer.css";
import { useEffect, useState } from "react";
import { BrandModel } from "../../Models/Brands/Brand";
import { getBrands } from "../../services/BrandService";
import { getSettings } from "../../services/SettingsService";
const BASE_URL = "https://muradiyekulturdernegi.org/panel/uploads/brands_v/";

const Footer: React.FC = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [brands, setBrands] = useState<BrandModel[]>([]);
  const [settings, setSettings] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const checkScroll = () => {
    if (window.scrollY > 500) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  useEffect(() => {
    window.addEventListener("scroll", checkScroll);
    return () => window.removeEventListener("scroll", checkScroll);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const brandsData = await getBrands();
        setBrands(brandsData);

        const settingsData = await getSettings();
        setSettings(settingsData[0]);
        
      } catch (error) {
        setError((error as Error).message);
      }
    }; fetchData();
  },[]);

  return (
    <div className="footer__section section__padding">
      {isVisible && (
        <a
          id="back-to-top"
          href="#"
          className="btn btn-light btn-lg back-to-top"
          role="button"
          onClick={scrollToTop}
        >
          <FaChevronUp />
        </a>
      )}

      {/*<p className="footer__section-heading">Kardeş Sayfalarımız</p>*/}
      <div className="footer__section-page_container py-2">
        {brands.map((brand) => (
          <div className="brand__container" key={brand.id}>
            <Link
              className="footer-link"
              to={brand.title ? brand.title : "#"}
              target="_blank"
            >
              <img src={`${BASE_URL}${brand.img_url}`} alt="" />
            </Link>
          </div>
        ))}
      </div>

      <div className="footer__section-content">
        <div className="footer__section-content_one">
          <p>
            <Link className="footer-link" to="/">
              Anasayfa
            </Link>
          </p>
          <p>
            <Link className="footer-link" to="/about-us">
              Hakkımızda
            </Link>
          </p>
          <p>
            <Link className="footer-link" to="/events">
              Etkinlikler
            </Link>
          </p>
          <p>
            <Link className="footer-link" to="/gallery">
              Galeri
            </Link>
          </p>
          <p>
            <Link className="footer-link" to="/contact">
              İletişim
            </Link>
          </p>
          <p>
            <Link className="footer-link" to="/donate">
              Bağış Yap
            </Link>
          </p>
        </div>

        <div className="footer__section-content_two">
          <h3>İletişim</h3>
          <p className="phone">
            <FaPhone /> {settings && settings.phone_1 ? settings.phone_1 : "Yükleniyor..."} 
          </p>
          <p>
            <FaEnvelope /> {settings && settings.email ? settings.email : "Yükleniyor..."}
          </p>
          <p>
            <FaMap />{settings && settings.address ? settings.address : "Yükleniyor..."}
          </p>

          <div className="footer__section-bottom_container-links">
            <Link type="button" title="Youtube" to={settings && settings.youtube ? settings.youtube : "#"}>
              <FaYoutube />
            </Link>
            <Link type="button" title="Instagram"  to={settings && settings.instagram ? settings.instagram : "#"}>
              <FaInstagram />
            </Link>
            <Link type="button" title="Our Book Store"  to="https://www.erkamyayinlari.com/">
              <FaBook />
            </Link>
          </div>
        </div>

        <div className="footer__section-content_three">
          <p>
            <Link className="footer-link" to="legal/suggestion-form">
              Talep - Öneri Formu
            </Link>
          </p>
          <p>
            <Link className="footer-link" to="/legal/privacy-policy">
              Gizlilik Politikası
            </Link>
          </p>
          <p>
            <Link className="footer-link" to="/legal/terms-of-use">
              Kullanım Koşulları
            </Link>
          </p>
          <p>
            <Link className="footer-link" to="legal/cookie-policy">
              Çerez Politikası
            </Link>
          </p>
        </div>
      </div>

      <div className="footer__section-copyright_container">
        <p className="copyright text-center">
          &copy; {new Date().getFullYear()} |{" "}
          <Link className="footer-link" to="https://feyzaerat.com.tr" target="_blank">
            <b>FKE Software</b>
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Footer;
