import React, { useEffect, useState } from 'react';
import Gallery from 'react-photo-gallery';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { getGalleryImages } from '../../services/ImageService';
import { ImageModel } from '../../Models/Gallery/Image';
import './gallery.css';
import { Photo } from './Photo';

const BASE_URL = "https://muradiyekulturdernegi.org/panel/";

const GalleryComponent = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [gallery, setGallery] = useState<Photo[]>([]);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const images: ImageModel[] = await getGalleryImages(5); 
                
                const formattedPhotos: Photo[] = images.map((image) => ({
                    src: `${BASE_URL}${image.url}`, 
                    width: 4,
                    height: 3, 
                }));
                setGallery(formattedPhotos); 
            } catch (error) {
                setError((error as Error).message);
            }
        };

        fetchImages();
    }, []);

    return (
        <div className="gallery container">
            {error}
            <Gallery
                photos={gallery}
                onClick={(event: React.MouseEvent<HTMLDivElement>, { index }: { index: number }) => {
                    setPhotoIndex(index);
                    setIsOpen(true);
                }}
            />
            {isOpen && (
                <Lightbox
                    mainSrc={gallery[photoIndex].src}
                    nextSrc={gallery[(photoIndex + 1) % gallery.length].src}
                    prevSrc={gallery[(photoIndex + gallery.length - 1) % gallery.length].src}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() => setPhotoIndex((photoIndex + gallery.length - 1) % gallery.length)}
                    onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % gallery.length)}
                />
            )}
        </div>
    );
};

export default GalleryComponent;
