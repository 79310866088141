import React from "react";
import "./about.css"
type Props = {};

const About = (props: Props) => {
  return (
    <div className="about">
      <div className="header__container">
        <p>Muradiye Kültür Derneği</p>
      </div>
      <div className="content__container">
        <p>
          Muradiye Kültür Derneği, 2021 yılında Kocaeli’de kurulmuştur. Muradiye
          kelime olarak “güzele ve iyiye dair” tüm dileklerin gerçekleştiği yer
          anlamındadır.
        </p>
        <p>
          Derneğimiz, İslam ahlak ve değerlerine bağlı ihlaslı nesiller
          yetiştirebilmeyi MURAD ederek ihtiyaç sahiplerine sığınak, gariplere
          barınak, yetim ve öksüzlere sıcak bir kucak olmayı, kurduğu
          müesseselerle hem yaralı gönüllere merhem, hem de memleketimizin
          yetişmiş insan ihtiyacına katkıda bulunmayı benimseyen ve hedefleyen
          gönül insanların bir araya gelerek kurmaya niyet ettikleri Muradiye
          Kültür Derneği bir nevi yüz yıllarca gönül fetihlerinin devam edeceği
          Muradiye Çınarının taze bir dal olarak topraktan filizlenişidir.
        </p>
        <p>
          Yıkılmış, umudunu yitirmiş gönüllerin güzele ve iyiye dair tüm
          dileklerini gerçekleştireceği, Müslümanlar arasında dayanışmayı
          sağlayarak manevi hizmet yolunun buluşma kapısı olmayı
          hedeflemektedir.
        </p>
        <p>
          Allah’ın bütün mahlûkatı ile ilişkilerimizde O’nun rızasını gözetip,
          insanların dert ve kederleri ile yakından ilgilenmek, muhtaç olanlara
          sâfî bir merhamet ve sevgi besleyerek yardımlarına koşmayı gaye
          edinerek eğitim verdiğimiz kişilerin ilmini, irfana dönüştürmelerini
          gaye edinerek bunun için çaba sarf etmek isteyenlerin, birçok şeyden
          fedakarlık ederek herhangi bir karşılık beklemeden, can ve mal ile
          hizmet etmeyi iştiyak haline getirip, gönlün derinliklerinde bu
          heyecanlı duyguyu güçlü ve coşku verici bir şekilde hissetmek
          isteyenlerin buluştukları kapıdır Muradiye..
        </p>
      </div>
    </div>
  );
};

export default About;
