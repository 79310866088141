import React, { useState } from "react";

import "./services.css";
type Props = {};

const HoverCard: React.FC = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      <div
        className="column"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div
          className={`post-module post-module__component ${
            isHovered ? "hover" : ""
          }`}
        >
          <div className="thumbnail">
            <img
              src="https://muradiyekulturdernegi.org/assets/carousel_item_boy_1"
              alt="Thumbnail"
            />
          </div>

          {/* Post Content */}
          <div className="post-content">
            <div className="category">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
            <h1 className="title">Yakında...</h1>

            <p className={`description ${isHovered ? "show" : ""}`}>
              Deprem Bölgesine gıda, giyecek, ısınma, çocuklar için kırtasiye
              yardımları yapılmaktadır.
            </p>
          </div>
        </div>
      </div>
      <div
        className="column"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div
          className={`post-module post-module__component ${
            isHovered ? "hover" : ""
          }`}
        >
          <div className="thumbnail">
            <img
              src="https://muradiyekulturdernegi.org/assets/IMG-20240422-WA0014.jpg"
              alt="Thumbnail"
            />
          </div>

          {/* Post Content */}
          <div className="post-content">
            <div className="category">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
            <h1 className="title">Yakında...</h1>

            <p className={`description ${isHovered ? "show" : ""}`}>
              Savaştaki Müslüman kardeşlerimize çadır, sıcak yemek ve giyecek
              yardımları yapılmaktadır.
            </p>
          </div>
        </div>
      </div>
      <div
        className="column"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div
          className={`post-module post-module__component ${
            isHovered ? "hover" : ""
          }`}
        >
          <div className="thumbnail">
            <img
              src="https://muradiyekulturdernegi.org/assets/carousel_item_girl_1"
              alt="Thumbnail"
            />
          </div>

          {/* Post Content */}
          <div className="post-content">
            <div className="category">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
            <h1 className="title">Yakında...</h1>

            <p className={`description ${isHovered ? "show" : ""}`}>
              Titizlikle belirlenen htiyaç sahiplerine düzenli olarak gıda,
              giyecek, yakacak, kırtasiye benzeri yardımlar yapılmaktadır.
            </p>
          </div>
        </div>
      </div>
      <div
        className="column"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div
          className={`post-module post-module__component ${
            isHovered ? "hover" : ""
          }`}
        >
          <div className="thumbnail">
            <img
              src="https://muradiyekulturdernegi.org/assets/carousel_item_girl_2"
              alt="Thumbnail"
            />
          </div>

          {/* Post Content */}
          <div className="post-content">
            <div className="category">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </div>
            <h1 className="title">Yakında...</h1>

            <p className={`description ${isHovered ? "show" : ""}`}>
              Yardıma muhtaç ülkelere ve oradaki ihtiyaç sahiplerine gıda, ilaç,
              su kuyusu, eğitim olanakları benzerinde yardımlar yapılmaktadır.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

const Services: React.FC<Props> = (props: Props) => {
  return (
    <div className="services">
      <div className="card__container">
        <div className="container__title">
          <p>Etkinliklerimiz</p>
        </div>
        <div className="container__content">
          <HoverCard />
        </div>
      </div>
    </div>
  );
};

export default Services;
