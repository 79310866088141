import React from "react";
import "./projects.css";
import { Link } from "react-router-dom";
type Props = {};

const Kurban = (props: Props) => {
  return (
    <div className="projects container">
      <div className="item__container">
        <div className="head__container">
          <p>Kurban Bağışı</p>
        </div>
        <div className="sub__head__container">
          <p>Yurtiçi Adak Kurbanı</p>
        </div>
        <div className="content__container">
          <p>
            Adak, akika, şükür ve nafile niyetiyle vakfımıza bağışlanan
            kurbanlar vekalet usulüyle kesilmektedir. Kesilen kurban etlerinin
            bir kısmı ihtiyaç sahiplerine, dul-yetim ve engelli ailelere
            dağıtılmakta bir kısmı da aşevlerinde ve Kur’an kursları,
            medreseler, yetimhaneler ve yurtlarda okuyan talebelerin
            yemeklerinde kullanılarak değerlendirilmektedir.
          </p>
          <p>
            Yurt içi adak kurban fiyatları kurbanlıkların cinsine ve büyüklüğüne
            göre 10.000TL, 14.000TL (Koç) ve 16.000TL (Koç) arasında
            değişmektedir. Yurt içi adak kurbanlar Üsküdar merkezimizde (Azîz
            Mahmûd Hüdâyî Hazretleri'nin türbesinin de içinde yer aldığı külliye
            içinde) küçükbaş olarak, yurt dışı adak kurbanlar ise faaliyetler
            gösterdiğimiz ülkelerde büyükbaş kurbanda bir hisse olarak
            kesilmektedir.
          </p>
          <p className="information">
            Yurt İçi Adak Kurban İrtibat: 0538 970 49 90
          </p>

          <p>
            <p>
              Online Bağış yapmak için{" "}
              <Link to="https://www.hudayivakfi.org.tr/online_bagis/adak-kurbani-turkiye">
                tıklayınız.
              </Link>
            </p>
          </p>
        </div>
        <div className="sub__head__container">
          <p>Yurtdışı Adak Kurbanı</p>
        </div>
        <div className="content__container">
          <p>
            Adak, akika, şükür, sadaka ve nafile niyetiyle vakfımıza bağışlanan
            kurbanlar belli sürelerde toplu hisseler (örneğin 70, 140 hisse)
            halinde, kurban sahiplerinin isimleri bizzat okunarak vekâlet
            usulüyle kesilmektedir. Kesilen kurban etlerinin bir kısmı ihtiyaç
            sahiplerine, savaş-terör mağduru mültecilere dağıtılmakta bir kısmı
            da aşevlerinde ve Kur’an kursları, medreseler, yetimhaneler ve
            yurtlarda okuyan talebelerin yemeklerinde kullanılarak
            değerlendirilmektedir.
          </p>
          <p>
            Adak, Akika, Şükür vb. vekâlet kurbanlarınızı Burkina Faso, Fildişi
            Sahili, Gana, Kamerun, Mali, Nijer, Sierra Leone, Suriye, Tanzanya
            ve Uganda gibi faaliyet gösterdiğimiz muhtelif ülkelerde büyükbaş
            kurbanda bir hisse olarak (sadece Suriye’de küçükbaş olarak)
            kesilmektedir. Hüdayi Vakfı vasıtası ile kestirerek ihtiyaç
            sahiplerini sevindirebilirsiniz.
          </p>
          <p>
            Kesim süresi ilgili ülkelerdeki eğitim vd. faaliyetlerimizin de
            yoğunluğuna göre 1 ila 2 haftalık bir süre içerisinde gerçekleşmekte
            ve “kesim yapıldı” bilgisi bağış sahiplerine kısa mesajla
            bildirilmektedir.
          </p>
          <p>
            Kurban Bayramı döneminde, vacip kurban fiyatları açıklandıktan sonra
            bağışlanan yurtdışı adak kurbanları kurban bayramında kesilmektedir.
          </p>
          <p>
            Yurt dışı adak kurban bedeli 4.750 TL’dir. Türkiye dışından bağış
            yapmak isteyen hayırseverlerimiz için 130 Euro’dur. Bağış yaparken
            açıklamaya; bağışçının adı, telefon numarası ve “vekâleti veriyorum”
            yazılması yeterlidir.
          </p>
          <p className="information">
            Yurt Dışı Adak Kurban İrtibat: 0532 691 48 32
          </p>
          <p>
            Online Bağış yapmak için{" "}
            <Link to="https://www.hudayivakfi.org.tr/online_bagis/adak-kurbani-yurtdisi">
              tıklayınız.
            </Link>
          </p>
          <p>
            <b>
              Havale veya EFT yaparken “Yurt İçi Kurban” veya “Yurt Dışı Kurban”
              başlığını seçtikten sonra açıklamaya telefon numaranızı ve
              vekaleti veriyorum yazarak bağışınızı ulaştırabilirsiniz.
            </b>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Kurban;
