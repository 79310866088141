import API from '../API/API';

import { EventModel } from "../Models/Events/Events";

export const getEvents =async() : Promise<EventModel[]> => {
    try{
        const response = await API.get<EventModel[]>('/events');
        return response.data;
    }
    catch(error){
        throw new Error ('Failed to fetch Events');
    }
}