import React from "react";
import Router from "../../routes/Router";
import { Navbar, Footer, Header } from "../../components";
import "../../styles/cards/cards.css";
import "./layout.css";

type Props = {};

const Layout = (props: Props) => {
  return (
    <>
      <Navbar />
      <div className="layout">
        <Header/>
        <Router />
      </div>
      <Footer />
    </>
  );
};

export default Layout;
