import React from "react";
import { Link, Outlet } from "react-router-dom";
import "./legalPage.css";
type Props = {};

const LegalPages = (props: Props) => {
  return (
    <div className="legal">
      <Outlet />
    </div>
  );
};

export default LegalPages;
