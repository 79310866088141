import React, { useEffect, useState } from "react";
import "./contact.css";
import { QrCode } from "../../components";
import { SettingModel } from "../../Models/Settings/Settings";
import { getSettings } from "../../services/SettingsService";

type Props = {};

const Contact: React.FC<Props> = () => {
  const [settings, setSettings] = useState<SettingModel | null>(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const settingsData = await getSettings();
        setSettings(settingsData[0]);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  if (!settings) {
    return <div>Loading...</div>;
  }
  return (
    <div className="contact">
      <div className="contact__section-container">
        <div className="contact__section-container_content-left">
          <div className="contact__section-container_content-address_container">
            <h1>İletişim</h1>
            <h3>Muradiye Kültür Derneği Ab-ı Hayat Konağı</h3>
            <h4 className="title">Telefon</h4>
            <p>{settings && settings.phone_1 ? settings.phone_1 : "Yükleniyor..."}</p>
            <h4 className="title">E-posta Adresi</h4>
            <p>
              <a href="mailto:iletisim@muradiyekulturdernegi.org">
              {settings && settings.email ? settings.email : "Yükleniyor..."}
              </a>
            </p>
            <h4 className="title">Adres</h4>
            <p>
              Kadıköy Mahallesi Taşer Sokak No:10
              <br />
              İzmit / KOCAELİ
            </p>
            <div className="qr-main">
              <div className="qr__row">
                <QrCode/>
              </div>
            </div>
            <small>
              <i>Navigasyon için Telefonunuzdan QR kodunu okutunuz..</i>
            </small>
          </div>
        </div>
        <div className="contact__section-container_content-right">
          <div className="contact__section-container_content-img_container">
            <img
              src="https://muradiyekulturdernegi.org/assets/ab-i_hayat.png"
              alt="Ab-ı Hayat Konağı"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
