import React from "react";
import { Route, Routes } from "react-router-dom";
import { Home, UserList, UserDetails, Users, AboutUs, Donate, Contact, Events, LegalPages, Institutions, Kurban, WaterWll, Egitim, Gallery } from "../pages";
import { CookiePolicy, PrivacyPolicy, SuggestionForm, TermsOfUse } from "../components";

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="*" element={<Home />} />
      <Route path="about-us" element={<AboutUs />} />
      <Route path="events" element={<Events />} />
      <Route path="gallery" element={<Gallery />} />
      <Route path="donate" element={<Donate />} />
      <Route path="contact" element={<Contact />} />
      <Route path="institutions" element={<Institutions />} />
      <Route path="kurban" element={<Kurban />} />
      <Route path="su-kuyulari" element={<WaterWll />} />
      <Route path="egitim" element={<Egitim />} />
      <Route path="/legal" element={<LegalPages />}>
          <Route path="cookie-policy" element={<CookiePolicy />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms-of-use" element={<TermsOfUse />} />
          <Route path="suggestion-form" element={<SuggestionForm />} />
          <Route index element={<LegalPages />} /> 
        </Route>
    </Routes>
  );
};

export default Router;
