import API from '../API/API';
import { SettingModel } from '../Models/Settings/Settings';


export const getSettings =async() : Promise<SettingModel[]> => {
    try{
        const response = await API.get<SettingModel[]>('/settings');
        return response.data;
    }
    catch(error){
        throw new Error ('Failed to fetch Brands');
    }
}