import React from 'react'
import "./header.css"
type Props = {}

const Header = (props: Props) => {
  return (
    <div className='banner'>
        
    </div>
  )
}

export default Header