import React from "react";

type Props = {};

const PrivacyPolicy = (props: Props) => {
  return (
    <div className="legal__container">
      <h1>Gizlilik Politikası</h1>
      <p>
        <small>
          <i>Güncelleme: 02 Aralık 2023</i>
        </small>
      </p>
      <p>
        Bu Gizlilik Politikası, Hizmeti kullanırken bilgilerinizi toplama,
        kullanma ve ifşa etme konusundaki politikalarımızı ve prosedürlerimizi
        açıklar ve gizlilik haklarınız ile yasaların sizi nasıl koruduğu
        hakkında sizi bilgilendirir.
      </p>
      <p>
        Kişisel verilerinizi Hizmeti sağlamak ve iyileştirmek amacıyla
        kullanıyoruz. Hizmeti kullanarak, bu Gizlilik Politikası'na uygun olarak
        bilgilerin toplanmasına ve kullanılmasına onay vermiş oluyorsunuz.
      </p>
      <h2>Yorum ve Tanımlar</h2>
      <h3>Yorum</h3>
      <p>
        Baş harfi büyük olan kelimelerin, aşağıdaki koşullara göre tanımlanmış
        anlamları vardır. Bu tanımlar, tekil ya da çoğul kullanımına
        bakılmaksızın aynı anlama gelir.
      </p>
      <h3>Tanımlar</h3>
      <p>Bu Gizlilik Politikası'nın amaçları doğrultusunda:</p>
      <ul>
        <li>
          <p>
            <strong>Hesap</strong>: Hizmetimize veya Hizmetimizin belirli
            bölümlerine erişim sağlamak için sizin adınıza oluşturulmuş
            benzersiz bir hesap anlamına gelir.
          </p>
        </li>
        <li>
          <p>
            <strong>İştirak</strong>: Bir tarafın kontrolünde, kontrolü altında
            veya ortak kontrol altında bulunan bir varlık anlamına gelir.
          </p>
        </li>
        <li>
          <p>
            <strong>Şirket</strong> (bu sözleşmede "Şirket", "Biz" veya "Bizim"
            olarak anılacaktır) Muradiye Kültür Derneği, Kadıköy Mahallesi Taşer
            Sokak No:10 İzmit / KOCAELİ anlamına gelir.
          </p>
        </li>
        <li>
          <p>
            <strong>Çerezler</strong>: Tarayıcınıza yerleştirilen ve geçmiş
            gezinme bilgilerinizi içeren küçük dosyalar anlamına gelir.
          </p>
        </li>
        <li>
          <p>
            <strong>Ülke</strong>: Türkiye anlamına gelir.
          </p>
        </li>
        <li>
          <p>
            <strong>Cihaz</strong>: Bilgisayar, cep telefonu veya dijital tablet
            gibi Hizmete erişim sağlayan herhangi bir cihaz anlamına gelir.
          </p>
        </li>
        <li>
          <p>
            <strong>Kişisel Veri</strong>: Kimliği belirli veya belirlenebilir
            bir kişiye ilişkin herhangi bir bilgi anlamına gelir.
          </p>
        </li>
        <li>
          <p>
            <strong>Hizmet</strong>: Web sitesi anlamına gelir.
          </p>
        </li>
        <li>
          <p>
            <strong>Hizmet Sağlayıcı</strong>: Şirket adına verileri işleyen
            herhangi bir gerçek veya tüzel kişi anlamına gelir. Şirket
            tarafından istihdam edilen üçüncü taraf şirketler veya bireyler de
            bu kapsama girer.
          </p>
        </li>
        <li>
          <p>
            <strong>Kullanım Verileri</strong>: Hizmetin kullanımıyla veya
            Hizmet altyapısından (örneğin, bir sayfada geçirilen süre) otomatik
            olarak toplanan veriler anlamına gelir.
          </p>
        </li>
        <li>
          <p>
            <strong>Web sitesi</strong>: Muradiye Kültür Derneği'nin web sitesi
            olan{" "}
            <a href="https://muradiyekulturdernegi.org/" target="_blank">
              https://muradiyekulturdernegi.org/
            </a>{" "}
            anlamına gelir.
          </p>
        </li>
        <li>
          <p>
            <strong>Sen</strong>: Hizmeti kullanan birey anlamına gelir.
          </p>
        </li>
      </ul>
    </div>
  );
};

export default PrivacyPolicy;
