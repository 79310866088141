import API from '../API/API';
import { EventImageModel } from '../Models/Events/EventImage';


export const getEventImages= async (): Promise<EventImageModel[]> => {
    try {
        const response = await API.get<EventImageModel[]>('/event-images');
        return response.data;
    } catch (error) {
        throw new Error('Failed to fetch users');
    }
};
