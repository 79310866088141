import React, { useEffect, useState } from "react";
import "./events.css";
import { BasicSelect, EventCard, Pagination } from "../../components";
import { SelectChangeEvent } from "@mui/material/Select";
import { TbCalendarDown } from "react-icons/tb";
import { ImSortAlphaAsc } from "react-icons/im";
import { PiWarningCircleLight } from "react-icons/pi";
import { Link } from "react-router-dom";
import { EventModel } from "../../Models/Events/Events";
import { getEvents } from "../../services/EventService";
import { EventCategoryModel } from "../../Models/Events/EventCategory";
import { getEventCategories } from "../../services/EventCategoryService";
import { EventImageModel } from "../../Models/Events/EventImage";
import { getEventImages } from "../../services/EventImageservice";

const BASE_URL = "https://muradiyekulturdernegi.org/panel/uploads/portfolios_v/";

const Events = () => {
  const [events, setEvents] = useState<EventModel[]>([]);
  const [eventCategories, setEventCategories] = useState<EventCategoryModel[]>([]);
  const [eventImages, setEventImages] = useState<EventImageModel[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [filteredEvents, setFilteredEvents] = useState<EventModel[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const [selectedLocation, setSelectedLocation] = useState("Tümü");
  const [sortCriteria, setSortCriteria] = useState<string>("");
  const [locations, setLocations] = useState<string[]>(["Tümü"]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const eventsData = await getEvents();
        const categoriesData = await getEventCategories();
        const imagesData = await getEventImages();
  
        const sortedEvents = eventsData.sort((a, b) => b.id - a.id);
  
        setEvents(sortedEvents);
        setEventCategories(categoriesData);
        setEventImages(imagesData);
  
        const uniqueLocations = Array.from(new Set(sortedEvents.map((e) => e.location)));
        setLocations(["Tümü", ...uniqueLocations]);
  
        setFilteredEvents(sortedEvents);
      } catch (error) {
        setError((error as Error).message);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);
  

  useEffect(() => {
    let updatedEvents = [...events];

    if (selectedLocation !== "Tümü") {
      updatedEvents = updatedEvents.filter(e => e.location === selectedLocation);
    }

    if (sortCriteria === "title") {
      updatedEvents.sort((a, b) => a.title.localeCompare(b.title));
    } else if (sortCriteria === "date") {
      updatedEvents.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    }

    setFilteredEvents(updatedEvents);
  }, [selectedLocation, sortCriteria, events]);

  const handleLocationChange = (event: SelectChangeEvent<string>) => {
    const location = event.target.value;
    setSelectedLocation(location);
    setCurrentPage(1); 
  };

  const handleSort = (criteria: string) => {
    setSortCriteria(criteria);
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastEvent = currentPage * itemsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - itemsPerPage;
  const currentPageEvents = filteredEvents.slice(indexOfFirstEvent, indexOfLastEvent);

  const getCategoryName = (categoryId: number) => {
    const category = eventCategories.find(c => c.id === categoryId);
    return category ? category.title : "Bilinmiyor";
  };

  const getEventImageUrl = (eventId: number) => {
    const image = eventImages.find(img => img.portfolio_id === eventId);
    return image ? `${BASE_URL}${image.img_url}` : "https://muradiyekulturdernegi.org/assets/deprem_1";
  };

  return (
    <div className="events container">
      <div className="filter__container">
        <div className="action__container">
          <button title="İsme Göre Sırala" onClick={() => handleSort("title")}>
            <ImSortAlphaAsc />
          </button>
          <button title="Tarihe Göre Sırala" onClick={() => handleSort("date")}>
            <TbCalendarDown />
          </button>

          <BasicSelect
            selectedLocation={selectedLocation}
            handleLocationChange={handleLocationChange}
            locations={locations}
          />
        </div>
        <div className="info__container">
          <Link to="/contact">
            <PiWarningCircleLight /> Detaylı Bilgi İçin Bizimle İletişime
            Geçiniz..
          </Link>
        </div>
      </div>

      <div className="event__container">
        {currentPageEvents.map(event => (
          <EventCard
            key={event.id}
            event={{
              ...event,
              category: getCategoryName(event.category_id),
              imageUrl: getEventImageUrl(event.id),
            }}
          />
        ))}
      </div>

      <Pagination
        totalItems={filteredEvents.length}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default Events;
