import API from '../API/API';
import { EventCategoryModel } from '../Models/Events/EventCategory';


export const getEventCategories= async (): Promise<EventCategoryModel[]> => {
    try {
        const response = await API.get<EventCategoryModel[]>('/event-categories');
        return response.data;
    } catch (error) {
        throw new Error('Failed to fetch users');
    }
};
