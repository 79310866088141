import React from "react";
import "./projects.css";
import { Link } from "react-router-dom";
type Props = {};

const Egitim = (props: Props) => {
  return (
    <div className="projects container">
      <div className="item__container">
        <div className="head__container">
          <p>Eğitim</p>
        </div>
        <div className="sub__head__container">
          <p>ÇOCUK VE GENÇLİK EĞİTİM HİZMETLERİ</p>
        </div>
        <div className="content__container">
          <p>
            Vakfımızın destek verdiği Gençlik Gelişim Merkezleri, gençlerimizin
            sosyal, sportif, ahlakî ve kültürel ihtiyaçlarını göz önüne alarak
            onların keyifli vakitler geçirebilecekleri, okul derslerine takviye
            alabilecekleri, bu sırada da karakterlerini geliştirebilecekleri,
            her türlü teknolojik ve sportif olanakların sunulmaya çalışıldığı
            nezih mekânlardır.
          </p>
        </div>
        <div className="sub__head__container">
          <p>İLİM MECLİSLERİ</p>
        </div>
        <div className="content__container">
          <p>
            Kur’an-ı Kerim ve Sünnet-i Seniyye’nin rehberliğinde, İslâmî
            İlimlere dair kaynak eserleri özümsemiş, tahsil ettiği ilmi irfan
            ile zenginleştirmiş, hizmet ehli diğerkâm ilahiyatçılar yetiştirme
            maksadıyla Hüdâyi İlahiyat Halkaları ve Suffe Meclisi adları altında
            çalışmalar düzenlenmektedir. Bu programlarda yetişen onlarca
            öğrencimiz akademik çalışmalara devam etmiş, doktora eğitimini
            tamamlayarak İlahiyat fakültelerinde araştırmacı ve hoca olmuştur.
            Yüzlercesi de diyanet hizmetleri ve eğitim sahalarında hoca veya
            öğretmen olarak hizmetlerine devam etmektedirler.
          </p>
        </div>
        <div className="sub__head__container">
          <p>KÜLTÜR MERKEZLERİ</p>
        </div>
        <div className="content__container">
          <p>
            Kültür merkezlerinde düzenlenen eğitim programları, insanın maddi ve
            manevi dünyasına hitap eden, ilmî ve ahlaki gelişimi destekleyen
            bütüncül bir yaklaşım benimsemektedir. Bu merkezlerde, geleneksel
            değerlerle çağın ihtiyaçlarını harmanlayan eğitimler sunularak
            bireylerin manevi olgunluk, topluma fayda ve kişisel yetkinlik
            kazanması amaçlanmaktadır. Modernleşmenin beraberinde getirdiği
            kimlik ve değer kaybını telafi etmeye yönelik çalışmalar,
            katılımcıları derin bir irfan yolculuğuna çıkarmakta, hayatı
            anlamlandırma ve ahlaki sorumluluk bilinci aşılanmaktadır.
          </p>
          <p>
            Merkezlerde verilen eğitimler, gönüllülük ruhuyla yoğrulmuş ve
            tasavvufi değerlerle beslenen ders halkaları aracılığıyla bireylerin
            içsel dünyalarına yönelmesini sağlayan birer kılavuz işlevi
            görmektedir. Katılımcılar, İslami ilimlerin yanı sıra, kişisel
            gelişim, sanat ve zanaat gibi farklı alanlarda da bilgi ve
            becerilerini geliştirirken, gönül eğitimiyle donanmış, bilge
            nesiller yetiştirme gayesi güdülmektedir.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Egitim;
