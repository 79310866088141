import API from '../API/API';

import { InstitutionsModel } from '../Models/Institutions/Institutions';

export const getInstitutions =async() : Promise<InstitutionsModel[]> => {
    try{
        const response = await API.get<InstitutionsModel[]>('/institutions');
        return response.data;
    }
    catch(error){
        throw new Error ('Failed to fetch Institutions');
    }
}