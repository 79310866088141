import React, { useEffect, useState } from "react";
import { Modal, Button } from "@mui/material"; // MUI bileşenleri
import "./modal.css";
import { Link } from "react-router-dom";

const TermsModal: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);


  useEffect(() => {
    const termsConsent = localStorage.getItem("termsConsent");
    if (!termsConsent) {
      setIsOpen(true);
    }
  }, []);


  const handleAccept = () => {
    localStorage.setItem("termsConsent", "accepted");
    setIsOpen(false);
  };

  // Reddetme işlemi
  const handleDecline = () => {
    alert("Kullanım şartlarını kabul etmeden siteyi kullanamazsınız.");
  };

  return (
    <Modal open={isOpen}>
      <div className="modal">
        <h2>Kullanım Şartlarını Kabul Edin</h2>
        <p>
          Web sitemizi kullanmadan önce{" "}
          <Link to="/legal/terms-of-use">Kullanım Şartları</Link> ve{" "}
          <Link to="legal/privacy-policy">Gizlilik Politikası</Link>'nı kabul
          etmelisiniz.
        </p>
        <div>
          <Button className="btn btn-success" onClick={handleAccept}>
            Kabul Et
          </Button>
          <Button className="btn btn-danger" onClick={handleDecline}>
            Reddet
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default TermsModal;
