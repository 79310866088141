import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "./slider.css";

import { DonationModel } from "../../Models/Donations/Donations";
import { getDonations } from "../../services/DonationService";

const BASE_URL = "https://muradiyekulturdernegi.org/panel/uploads/donations_v/";

const Slider: React.FC = () => {
  const [donations, setDonations] = useState<DonationModel[]>([]);

  useEffect(() => {
    const fetchDonations = async () => {
      try {
        const donationData: DonationModel[] = await getDonations();
        if (donationData.length < 2) {
          const placeholderImages: DonationModel[] = [
            {
              id: 199,
              title: "Placeholder 1",
              img_url: "https://via.placeholder.com/400",
              count: 0,
            },
            {
              id: 299,
              title: "Placeholder 2",
              img_url: "https://via.placeholder.com/400",
              count: 0,
            },
          ];
          setDonations([...donationData, ...placeholderImages]);
        } else {
          setDonations(donationData);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchDonations();
  }, []);

  return (
    <div className="section__carousel container">
      <Carousel
        autoPlay={true}
        interval={3000}
        infiniteLoop={true}
        dynamicHeight={true}
      >
        {donations.map((donation, index) => (
          <div key={index}>
            <img
              src={`${BASE_URL}${donation.img_url}`}
              alt={`carousel item ${index}`}
            />
            <div className="carousel-text-container">
              <div className="header__container">
                <p>
                  {donation.title}
                </p>
              </div>
              <div className="content__container"></div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Slider;
