import React from "react";

import { CookieModal, Layout } from "./components";
import "./App.css"

function App() {
  return (
    <>
      <Layout />
      <CookieModal />
    </>
  );
}

export default App;
