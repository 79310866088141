import React from "react";
import "./abutUs.css"
type Props = {};

const AboutUs = (props: Props) => {
  return (
    <div className="about-us container">
      <div className="paragraph__container">
        <div className="header__container">
        <div className="ribbon"><p>Biz Kimiz ?</p></div>
        </div>
        <div className="content__container">
          <p>
            Muradiye Kültür Derneği, 2010 yılında İstanbul’da kurulmuş bir sivil
            toplum kuruluşudur. Dernek, toplumsal dayanışmayı artırmak, eğitim
            ve kültür faaliyetleriyle bireylerin manevi gelişimine katkıda
            bulunmak amacıyla faaliyet göstermektedir. Türkiye’deki ve dünyadaki
            yardıma muhtaç insanlara destek olmayı amaçlayan dernek, birçok
            farklı alanda sosyal sorumluluk projeleri yürütmektedir.
          </p>
        </div>
      </div>
      <div className="paragraph__container">
        <div className="header__container">
        <div className="ribbon"><p>Misyonumuz</p></div>
        </div>
        <div className="content__container">
          <p>
            Muradiye Kültür Derneği’nin misyonu, manevi ve ahlaki değerleri
            koruyarak bireylerin sosyal, kültürel ve eğitimsel gelişimlerine
            katkıda bulunmaktır. İhtiyaç sahiplerine yardımlaşma, dayanışma ve
            eğitim olanakları sunarak toplumsal barış ve huzuru sağlamaya
            yönelik projeler gerçekleştirmektedir.
          </p>
        </div>
      </div>
      <div className="paragraph__container">
        <div className="header__container">
        <div className="ribbon"><p>Vizyonumuz</p></div>
        </div>
        <div className="content__container">
          <p>
            Vizyonumuz, daha adil, merhametli ve manevi değerlere sahip bir
            toplum oluşturmaktır. Muradiye Kültür Derneği, geleceğin nesillerine
            daha yaşanabilir bir dünya bırakmayı hedeflerken, insan onuruna
            yakışır şekilde hizmet vermeyi ve topluma fayda sağlayan projelere
            öncülük etmeyi amaçlar.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
