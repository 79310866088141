import React, { useState } from "react";
import "../../styles/forms/forms.css";

type Props = {};

const SuggestionForm: React.FC<Props> = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [formData, setFormData] = useState({
    yrnm: "", 
    yrsrnm: "",
    yrml: "",
    yrsbjct: "", 
    "your-message": "", 
  });

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const response = await fetch(
        "https://muradiyekulturdernegi.org/panel/api/contact",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
          credentials: "include",
        }
      );
      const text = await response.text();
     
      const result = JSON.parse(text);
      if (result.status === "success") {
        setPopupMessage("Form başarıyla gönderildi!");
      } else {
        setPopupMessage("Bir hata oluştu: " + result.message);
      }

      setShowPopup(true);
    } catch (error: unknown) {
      if (error instanceof Error) {
        setPopupMessage("Bir hata oluştu. Hata: " + error.message);
      } else {
        setPopupMessage("Bir hata oluştu."); 
      }
      setShowPopup(true);
    }
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      <div className="container my-5 suggestion">
        <div className="row justify-content-center">
          <div className="col-lg-9">
            <p className="mb-3 head">Talep - Öneri Formu</p>
            <form onSubmit={handleSubmit}>
              <div className="row g-3">
                <div className="col-md-6">
                  <p className="form-label mt-2">Adınız</p>
                  <input
                    type="text"
                    className="form-control"
                    name="yrnm" 
                    value={formData.yrnm}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="col-md-6">
                  <p className="form-label mt-2">Soyadınız</p>
                  <input
                    type="text"
                    className="form-control"
                    name="yrsrnm"
                    value={formData.yrsrnm}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="col-md-6">
                  <p className="form-label mt-2">E-Posta Adresiniz</p>
                  <input
                    type="email"
                    className="form-control"
                    name="yrml" 
                    value={formData.yrml}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="col-md-6">
                  <p className="form-label mt-2">Konu Başlığı</p>
                  <input
                    type="text"
                    className="form-control"
                    name="yrsbjct" 
                    value={formData.yrsbjct}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12">
                  <p className="form-label mt-2">Mesajınız</p>
                  <textarea
                    className="form-control"
                    name="your-message" 
                    rows={5}
                    value={formData["your-message"]}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-send w-100 fw-bold mt-5"
                  >
                    Gönder
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {showPopup && <Popup message={popupMessage} onClose={closePopup} />}
    </>
  );
};

const Popup = ({
  message,
  onClose,
}: {
  message: string;
  onClose: () => void;
}) => {
  return (
    <div className="popup">
      <div className="popup-content">
        <p>{message}</p>
        <button className="btn btn-send" onClick={onClose}>
          Kapat
        </button>
      </div>
    </div>
  );
};

export default SuggestionForm;
